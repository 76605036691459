// Here you can add other styles
.label-info {
  color: #9b9696;
  font-weight: 700;
}
.user-info-text {
  color: #5c5c5c;
  font-weight: 700;
}
.back-arrow {
  color: #7267d3 !important;
  cursor: pointer;
  margin-right: 10px;
}
.plus-sign {
  width: 50px;
  height: 50px;
  background: rgba(58,59,68,0.5);;
  border-radius: 50%;
  color: #6c6e7e;
  font-size: 35px;
  margin-top: -40px;
}

